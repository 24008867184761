<template>
<!--  <Header :header="header" @multi-selection="multiSelection" @search="activeSearch"/>-->
  <a-dims-search-bar
    placeholder="Buscar Foodie"
    hide-search
    @search="fetch({ search: $event })">
    <router-link
      active-class="font-bold border-b-2 border-primary text-primary"
      to="/explore"
      class="inline-block py-4">
      Explorar
    </router-link>
    <router-link
      active-class="font-bold border-b-2 border-primary text-primary"
      to="/interested"
      class="inline-block py-4 ml-small">
      Solicitudes
    </router-link>
    <router-link
      active-class="font-bold border-b-2 border-primary text-primary"
      to="/favorites"
      class="inline-block py-4 ml-small">
      favoritos
    </router-link>

    <template #results>
      <div class="flex justify-center items-center flex-col" v-if="loading">
        <a-loader color="text-gray" class="h-10 w-10" />
        <h1 class="text-2xl text-white">Buscando</h1>
      </div>
    </template>
    <!-- <template #results>
      <p class="text-xl text-white text-left">Resultados</p>
      <div class="bg-white p-2 rounded-lg">
        <a-list
          :source="pagination.data"
          :loading="loading"
          #default="{ item, index }">
          <restaurant-cell
            @attach="attach(index)"
            @detach="detach(index)"
            :restaurant="item"
            :foodie="user.foodie.id" />
        </a-list>
      </div>
    </template> -->
  </a-dims-search-bar>

<!--  <div class="flex grid-header padding-header">-->
<!--&lt;!&ndash;    <div class="flex-grow">&ndash;&gt;-->
<!--&lt;!&ndash;      <p class="text-left text-dgray font-bold uppercase">Foodies populares</p>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--    <a-dropdown>-->
<!--      <p class="text-left text-gray-400 px-2">Ordenar por</p>-->
<!--      <a-dropdown-item @click="fetch({ all: true })">-->
<!--        Todos los foodies-->
<!--      </a-dropdown-item>-->
<!--      <a-dropdown-item @click="fetch({ is_premium: 1, page: 1 })">-->
<!--        Foodies premium-->
<!--      </a-dropdown-item>-->
<!--&lt;!&ndash;      <a-dropdown-item @click="fetch({ sort_by: 'created_at,asc' })">&ndash;&gt;-->
<!--&lt;!&ndash;        Mas recientes al final&ndash;&gt;-->
<!--&lt;!&ndash;      </a-dropdown-item>&ndash;&gt;-->
<!--    </a-dropdown>-->
<!--  </div>-->

  <div class="flex mt-16">
    <div class="flex-grow text-left mx-normal my-small flex border border-gray rounded-full items-center overflow-hidden">
      <input
          ref="search"
          autofocus
          v-debounce:[400]="search => fetch( {search: search, page: 1})"
          placeholder="Buscar Foodies"
          class="flex-auto bg-opacity-75 bg-white text-black focus:outline-none p-2">
      <i v-if="!loading" class="icon-search inline flex-none p-2 text-xl" />
      <a-loader v-else class="h-6 w-6 mr-2" :color="'text-gray'"/>
    </div>

    <div class="flex items-center">
      <select class="border py-2 rounded px-2" v-model="query.state" @change="fetch()">
        <option value="madrid">Madrid</option>
        <option value="barcelona">Barcelona</option>
        <option value="sevilla">Sevilla</option>
        <option value="bilbao">Bilbao</option>
        <option value="valencia">Valencia</option>
        <option value="málaga">Málaga</option>
      </select>
    </div>

    <div class="flex ml-1 items-center">
      <a-dropdown class="mr-normal align-middle">
        <p class="text-left text-gray-400 px-2">Ordenar por</p>
        <a-dropdown-item @click="fetch({ all: true })">
          Todos los foodies
        </a-dropdown-item>
        <a-dropdown-item @click="fetch({ is_premium: 1, page: 1 })">
          Foodies premium
        </a-dropdown-item>
      </a-dropdown>
    </div>
  </div>

  <a-list
      class="flex flex-col gcp-restaurant-explore"
      :class="button ? 'public-collab' : ''"
      :source="pagination.data"
      :loading="loading"
      :should-emit-bottom-reached="pagination.next_page_url !== null"
      @bottom-reached="fetch({ page: pagination.next_page_url ? pagination.current_page + 1 : pagination.current_page }, true)"
      #default="{ item }">
    <GridElement :value="item"
                 class="grid-element-padding"
                 :multi="multi"
                 @selected-foodie="addFoodie"
                 @unselected-foodie="unselectFoodie">
    </GridElement>
  </a-list>
<!--  <div class="gcp-restaurant-explore padding-header" :class="button ? 'public-collab' : ''">-->
<!--    <Grid :multi="multi" :view-type="'explore'" @show-button="viewButton"></Grid>-->
<!--  </div>-->
  <div v-show="button && multi" class="block cbc bg-white">
    <button @click="createMultiCollab"
            class="button bg-primary font-normal text-white uppercase h-base">
      {{'invitar a ' + totalFoodies + ' Foodies' }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import GridElement from '@/components/authenticated/GridElement'
// import Header from '@/components/authenticated/Header'

export default {
  name: 'Explore',
  props: {
    values: Object
  },
  components: {
    GridElement
    // Header
  },
  data () {
    return {
      // values: [],
      header: {
        type: 'explore',
        config: 'explore'
      },
      multi: false,
      search: false,
      button: false,
      totalFoodies: 0,
      foodies: [],
      modal: null,
      pagination: { data: [] },
      loading: false,
      query: {
        limit: 25,
        state: '',
        // sort_by: 'created_at,desc',
        explore: 1,
        with_count: 'completedCollabs'
      }
    }
  },
  computed: {
    ...mapState({
      user: ({ session }) => session.user
      // pagination: state => state.foodies.pagination,
      // loading: state => state.foodies.loading.index
    }),
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    })
  },
  methods: {
    multiSelection (multi, search) {
      this.multi = multi
      this.search = search
      // console.log(this.multi)
    },
    addFoodie (id) {
      // this.foodie.push(foodie.valueOf())
      this.foodies.push(id)
      this.selected = true
      this.viewButton()
    },
    unselectFoodie (id) {
      // this.foodie.push(foodie.valueOf())
      this.foodies.splice(this.foodies.indexOf(id), 1)
      this.selected = false
      this.viewButton()
    },
    viewButton () {
      this.button = this.foodies.length > 0
      this.totalFoodies = this.foodies.length
    },
    createMultiCollab () {
      if (this.foodies.length > 1) {
        this.$router.push({ name: 'collaboration.create.multi', query: { ids: this.foodies.join(',') } })
      } else {
        this.$router.push({ path: `/collaboration/create/${this.foodies}` })
      }
    },
    update (collaboration, index) {
      this.pagination = {
        ...this.pagination,
        data: this.pagination.data
          .map(($0, $1) => $1 === index ? { ...$0, ...collaboration } : $0)
      }
    },
    fetch (segment = {}, appending = false) {
      this.loading = !this.loading
      let query
      if (segment.all === true) {
        query = {
          limit: 25,
          sort_by: 'created_at,desc',
          with_count: 'completedCollabs',
          page: 1,
          explore: 1
        }
      } else {
        query = { ...this.query, ...segment }
      }

      // let query
      // if (segment === 'premium') {
      //   query = { ...this.query, is_premium: true }
      // } else {
      //   if ('is_premium' in this.query) {
      //     delete this.query.is_premium
      //   }
      //
      //   query = { ...this.query }
      // }
      //
      this.$repository.foodies
        .index(query)
        .then(({ data: pagination }) => {
          this.pagination = appending
            ? { ...pagination, data: [...this.pagination.data, ...pagination.data] }
            : pagination

          if (this.$route.query.collaboration_with) {
            this.pagination.data = this.pagination.data
              .sort((a, b) => a.id === parseInt(this.$route.query.collaboration_with) ? -1 : 1)
          }
          this.query = query
        })
        .finally(() => (this.loading = !this.loading))
    }
  },
  mounted () {
    if (this.user.isRestaurant) {
      // console.log(this.user.restaurant.state)
      this.query.state = this.user.restaurant.state ? (this.user.restaurant.state).toLowerCase() : 'madrid'
      this.fetch()
    }
  }
}
</script>

<style scoped>
.button{
  margin: 0 !important;
}
</style>
