<template>
  <div>
    <component :is="`explore-${user.isFoodie ? 'foodie' : 'restaurant'}`" :values="values"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import exploreFoodie from '@/views/authenticated/foodies/explore/Explore.vue'
import exploreRestaurant from '@/views/authenticated/restaurants/Explore.vue'

export default {
  name: 'Explore',
  data () {
    return {
      values: []
    }
  },
  components: {
    exploreFoodie,
    exploreRestaurant
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    })
  },
  methods: {
    ...mapActions({
      foodies: 'foodies/index'
    })
    // getUserValues () {
    //   if (this.isAuthenticated) {
    //     if (this.user.isRestaurant) {
    //       this.foodies().then(response => (this.values = response))
    //     }
    //   }
    // }
  // },
  // created () {
  //   this.getUserValues()
  }
}
</script>

<style scoped>

</style>
