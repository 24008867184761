<template>
  <a-dims-search-bar
    placeholder="Buscar restaurante"
    hide-search
    @search="fetch({ search: $event, explore: 1 })">

    <router-link
      active-class="border-b-2 border-primary text-primary"
      to="/explore"
      class="inline-block py-4 font-bold">
      Explorar
    </router-link>
    <router-link
      active-class="border-b-2 border-primary text-primary"
      to="/favorites"
      class="inline-block py-4 ml-small font-bold">
      solicitados
    </router-link>

    <template #results>
      <div class="flex justify-center items-center flex-col" v-if="loading">
        <a-loader color="text-gray" class="h-10 w-10" />
        <h1 class="text-2xl text-white">Buscando</h1>
      </div>
    </template>

    <template #cancel>
      <button @click="fetch({ search: '', explore: 1 })">Cancelar</button>
    </template>
  </a-dims-search-bar>

<!--  <div class="flex px-4 pt-16">-->
<!--    <div class="flex-grow">-->
<!--&lt;!&ndash;      <h3 class="text-left uppercase font-bold text-dgray">&ndash;&gt;-->
<!--&lt;!&ndash;        Restaurantes populares&ndash;&gt;-->
<!--&lt;!&ndash;      </h3>&ndash;&gt;-->
<!--    </div>-->
<!--    <div class="flex-none">-->
<!--      <a-dropdown>-->
<!--        <p class="text-left text-gray-400 px-2">Ordenar por</p>-->
<!--        <a-dropdown-item @click="fetch({ sort_by: 'created_at,desc', explore: 1 })">-->
<!--          Mas recientes primero-->
<!--        </a-dropdown-item>-->
<!--        <a-dropdown-item @click="fetch({ sort_by: 'created_at,asc', explore: 1 })">-->
<!--          Mas recientes al final-->
<!--        </a-dropdown-item>-->
<!--      </a-dropdown>-->
<!--    </div>-->
<!--  </div>-->

  <div class="flex pt-16">
    <div class="flex-grow text-left mx-normal my-small flex border border-gray rounded-full items-center overflow-hidden">
      <input
          ref="search"
          autofocus
          v-debounce:[400]="search => searchRest( { search: search, page: 1 })"
          placeholder="Buscar Restaurante"
          class="flex-auto bg-opacity-75 bg-white text-black focus:outline-none p-2">
      <i v-if="!loading" class="icon-search inline flex-none p-2 text-xl" />
      <a-loader v-else class="h-6 w-6 mr-2" :color="'text-gray'"/>
    </div>

    <div class="flex items-center mr-normal">
      <select class="border py-2 rounded px-2" v-model="query.state" @change="fetch()">
        <option value="madrid">Madrid</option>
        <option value="barcelona">Barcelona</option>
        <option value="sevilla">Sevilla</option>
        <option value="bilbao">Bilbao</option>
        <option value="valencia">Valencia</option>
        <option value="málaga">Málaga</option>
      </select>
    </div>

<!--    <div class="flex ml-1 items-center">-->
<!--      <a-dropdown class="mr-normal align-middle">-->
<!--        <p class="flex-none text-left text-gray-400 px-2">Ordenar por</p>-->
<!--        <a-dropdown-item @click="fetch({ sort_by: 'created_at,desc', explore: 1 })">-->
<!--          Mas recientes primero-->
<!--        </a-dropdown-item>-->
<!--        <a-dropdown-item @click="fetch({ sort_by: 'created_at,asc', explore: 1 })">-->
<!--          Mas recientes al final-->
<!--        </a-dropdown-item>-->
<!--      </a-dropdown>-->
<!--    </div>-->
  </div>

  <a-list
    class="px-4 pb-16"
    :source="pagination.data"
    :loading="loading"
    :should-emit-bottom-reached="pagination.next_page_url !== null"
    @bottom-reached="fetch({
      page: pagination.next_page_url ? pagination.current_page + 1 : pagination.current_page
    }, true)"
    #default="{ item }">

    <restaurant-cell
      @fetch="fetch({ explore: 1, page: 1, limit: 25 })"
      :restaurant="item"
      :foodie="user.foodie.id" />
  </a-list>

<!--  <a-alert @cancel="adviced = null" :show="adviced != null" @confirm="adviced = null">-->
<!--    <div class="text-left space-y-4">-->
<!--      <h1 class="text-lg font-semibold">¡Ya estás en la lista de espera!</h1>-->
<!--      <p>Gracias por tu interés, <b>@{{ adviced.ig_username }}</b> ha sido avisado y pronto serás notificado</p>-->
<!--    </div>-->
<!--  </a-alert>-->
</template>

<script>
import { mapState } from 'vuex'
import RestaurantCell from './restaurant-cell'

export default {
  props: {
    values: Object
  },
  components: {
    RestaurantCell
  },
  data: () => ({
    // rstate: 'madrid',
    adviced: null,
    pagination: { data: [] },
    loading: false,
    query: {
      limit: 25,
      explore: 1,
      state: ''
      // sort_by: 'created_at,desc'
    }
  }),
  computed: mapState(({
    user: ({ session }) => session.user
  })),
  methods: {
    fetch (segment = {}, appending = false) {
      this.loading = !this.loading
      const query = { ...this.query, ...segment }

      this.$repository.restaurants
        .index(query)
        .then(({ data: pagination }) => {
          this.pagination = appending
            ? { ...pagination, data: [...this.pagination.data, ...pagination.data] }
            : pagination

          this.query = query
        })
        .finally(() => (this.loading = !this.loading))
    },
    searchRest (segment = {}) {
      this.loading = !this.loading
      const query = { ...this.query, ...segment }
      this.$repository.restaurants
        .index(query)
        .then(({ data: pagination }) => {
          this.pagination = pagination
        })
        .finally(() => (this.loading = !this.loading))
    },
    // attach (index) {
    //   this.pagination = {
    //     ...this.pagination,
    //     data: this.pagination.data.map(($0, key) => key === index ? { ...$0, foodie_is_interested: true } : $0)
    //   }
    //   this.adviced = this.pagination.data[index]
    // },
    // detach (index) {
    //   this.pagination = {
    //     ...this.pagination,
    //     data: this.pagination.data.map(($0, key) => key === index ? { ...$0, foodie_is_interested: false } : $0)
    //   }
    // }
    getRestaurants (e) {
      console.log(e.target.value)
    }
  },
  mounted () {
    this.query.state = this.user.foodie.state ? (this.user?.foodie?.state).toLowerCase() : 'madrid'
    this.fetch()
  }
}
</script>
